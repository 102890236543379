// Kennel
@import 'styles/kennel/kennel';

// Utils
@import 'styles/utils/variables';

.button {
  display: inline-block;
  padding: 0;
  outline: none;
  border: 0 none;
  border-radius: 45px;
  background: transparent;
  font-family: $font-sans-serif;
  font-size: rem(14px);
  line-height: (18 / 14);
  letter-spacing: -0.5px;
  text-align: center;
  transition: color 0.3s $ease-out-quart, background-color 0.3s $ease-out-quart;

  &--m {
    @include size(auto, 56px);
    @include padding(19px 32px);
    font-weight: 700;
  }

  &--s {
    @include size(auto, 48px);
    @include padding(15px 28px);
    font-weight: 600;
  }

  &--orange {
    color: $brand-white;
    background-color: $brand-orange;

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      background-color: $brand-orange-dark;
    }
  }

  &--orange-lighter {
    color: $brand-orange-dark;
    background-color: $brand-orange-lighter;

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      background-color: $brand-orange-light;
    }
  }
}
