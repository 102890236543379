// Kennel
@import 'styles/kennel/kennel';

// Vendors (See package.json)
@import 'sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/breakpoints';

.site-header {
  @include position(absolute, 0 null null 0);
  @include size(100%, auto);
  z-index: 999;

  .container {
    @include mq($until: 'm') {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 50px;
      padding-bottom: 20px;
    }

    &::after {
      @include position(absolute, null 40px 0);
      @include size(auto, 1px);
      background-color: $brand-beige-light;

      @include mq($until: 'm') {
        content: '';
      }
    }
  }

  &__logo {
    @include position(relative);
    @include size(194px, 37px);
    z-index: 1;
    display: block;
    background-image: image('header/logo@2x.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;

    @include mq($from: 'm') {
      @include position(absolute, 50% null null 50%);
      @include margin(-18px null null -97px);
    }
  }

  &__menu,
  &__items {
    @include mq($from: 'm') {
      display: flex;
      align-items: center;
    }
  }

  &__menu {
    padding-top: 25px;
    padding-bottom: 25px;
    justify-content: space-between;
    border-bottom: 1px solid $brand-beige-light;

    @include mq($until: 'm') {
      @include position(fixed, 0 null null 0);
      @include padding(255px 40px 60px 40px);
      @include size(100%);
      z-index: -1;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      background-color: $brand-beige-lighter;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__items {
    li + li {
      @include mq($from: 'm') {
        margin-left: 32px;
      }

      @include mq($until: 'm') {
        margin-top: 30px;
      }
    }

    + .site-header__items {
      @include mq($until: 'm') {
        margin-top: 30px;
      }
    }
  }

  &__link {
    @include position(relative);
    @include padding(null null 10px);
    display: inline-block;
    color: $brand-blue-darker;
    font-family: $font-sans-serif;
    font-size: rem(14px);
    font-weight: 600;
    line-height: (18 / 14);
    letter-spacing: -0.8px;

    @include mq($until: 'm') {
      font-size: rem(18px);
      line-height: (28 / 18);
      letter-spacing: -1px;
    }

    &::after {
      @include position(absolute, null null 0 0);
      @include size(100%, 2px);
      content: '';
      background-color: $brand-blush;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform 0.4s $ease-in-out-expo;
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      &::after {
        transform-origin: 0% 50%;
        transform: scaleX(1);
        transition: transform 0.7s $ease-out-expo;
      }
    }

    &.is-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__button {
    @include mq($from: 'm') {
      margin-top: -2px;
    }

    @include mq($until: 'm') {
      position: absolute;
      top: 150px;
      right: 40px;
      left: 40px;
    }
  }

  &__hamburger {
    @include position(relative);
    @include size(24px);

    @include mq($from: 'm') {
      display: none;
    }

    span {
      @include position(absolute, 50% null null 50%);
      @include size(20px, 2px);
      @include margin(-1px null null -10px);
      background-color: $brand-blue-darker;

      &:nth-child(1),
      &:nth-child(2) {
        transition: transform 0.3s $ease-out-quart;
      }

      &:nth-child(1) {
        transform: translateY(-6px);
      }

      &:nth-child(2) {
        transform: translateY(6px);
      }

      &:nth-child(3) {
        transition: opacity 0.3s $ease-out-quart;
      }
    }
  }

  &--open {
    .container {
      @include mq($until: 'm') {
        background-color: $brand-beige-lighter;
      }
    }

    .site-header__menu {
      @include mq($until: 'm') {
        display: flex;
      }
    }

    .site-header__hamburger {
      span:nth-child(1) {
        transform: rotate(-45deg);
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        opacity: 0;
      }
    }
  }
}
