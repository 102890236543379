// Grid System
@function gs($columns, $gutters, $base-width: 1160px, $base-columns: 12, $base-gutter: 40px) {
  // Calculate the space taken by gutters
  $gs-gutters-width: ($base-columns - 1) * $base-gutter;

  // Calculate a single column width
  $gs-colum-width: calc((#{$base-width} - #{$gs-gutters-width}) / #{$base-columns});

  // Return the width for N columns
  @return calc((#{$columns} * #{$gs-colum-width}) + (#{$gutters} * #{$base-gutter}));
}
