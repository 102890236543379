// Vendors (See package.json)
@import 'sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/breakpoints';

// Set up a decent box model on the root element
// -----------------------------------------------------------------------------
html {
  height: 100%;
  box-sizing: border-box;
}

// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

// Set up body size to fill screen
// -----------------------------------------------------------------------------
body {
  width: 100%;
  height: 100%;
  background-color: $brand-beige-lighter;
}
